.ListDrop {
  position: absolute;
  z-index: 9999;
  background-color: #f7f7f7;
  margin-top: -8px;
  margin-left: 8px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  border: 1px #c7c7c7 solid;
  border-top: 0;

  .Result {
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff;
    }
  }
}
