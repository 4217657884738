body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

body, html, #root {
  height: 100%;
}

.btn-primary {
  background-color: #f67b2f !important;
  border: 1px solid #f67b2f !important;
}

.btn-success {
  background-color: #f67b2f !important;
  border: 1px solid #f67b2f !important;
}

.btn-secondary {
  background-color: #f67b2f !important;
  border: 1px solid #f67b2f !important;
}

.wallpaper {
  background: url(/static/media/wallpaper.ee629305.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.list-group-item.active {
  background-color: #f67b2f !important;
  border: 1px solid #f67b2f !important;
}
.Box_container__3FhG8 {
  border: 1px #c7c7c7 solid;
  box-shadow: -5px -1px 5px #f1f1f1; }

.Box_padded__rvaHy {
  padding: 16px 32px; }

.Box_title__ZPpl1 {
  color: #444444;
  font-size: 32px; }

.Box_subtext__qiAnz {
  color: #999999; }

.MinimalLayout_container__oI-ip {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%; }
  .MinimalLayout_container__oI-ip .MinimalLayout_content__XqoPt {
    margin: auto; }
    .MinimalLayout_container__oI-ip .MinimalLayout_content__XqoPt .MinimalLayout_safeMargin__2TwJX {
      margin-top: 32px;
      margin-bottom: 32px; }

.FormikInput_label__lBNof {
  font-weight: 500;
  display: block;
  margin-bottom: 4px; }

.BoxBody_container__2decg {
  padding: 16px 32px; }

.DashboardLayout_sidebar__26e7r {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); }
  .DashboardLayout_sidebar__26e7r .DashboardLayout_navLink__3fhaB {
    display: block;
    font-weight: 500;
    color: #333;
    padding: 8px 16px;
    margin-bottom: 4px;
    cursor: pointer; }
    .DashboardLayout_sidebar__26e7r .DashboardLayout_navLink__3fhaB.DashboardLayout_active__QF35X {
      color: #007bff; }
    .DashboardLayout_sidebar__26e7r .DashboardLayout_navLink__3fhaB:hover {
      text-decoration: none; }
  .DashboardLayout_sidebar__26e7r .DashboardLayout_cursor__63fKu {
    display: block;
    font-weight: 500;
    color: #333;
    padding: 8px 16px;
    margin-bottom: 4px;
    cursor: pointer; }
  .DashboardLayout_sidebar__26e7r .DashboardLayout_sticky__3vkOO {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    /* Height of navbar */
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */ }
  .DashboardLayout_sidebar__26e7r h6 {
    font-weight: normal;
    margin-top: 32px;
    text-transform: uppercase;
    font-size: 14px;
    color: #999999; }

.DashboardLayout_navbarBrand__4yi3i {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  text-align: center; }
  .DashboardLayout_navbarBrand__4yi3i img {
    height: 48px;
    margin: 5px; }

.DashboardLayout_left__-X--1 {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-left: 32px !important;
  margin: 0 !important; }

.DashboardLayout_right__Ol4EU {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 !important;
  color: #ffffff;
  width: 100%; }
  .DashboardLayout_right__Ol4EU .DashboardLayout_message__2gbmE {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: right;
    margin-top: 3px; }
  .DashboardLayout_right__Ol4EU .DashboardLayout_avatar__1-MeJ {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block; }
    .DashboardLayout_right__Ol4EU .DashboardLayout_avatar__1-MeJ img {
      height: 32px;
      margin-top: -1px;
      border-radius: 100px;
      border: 1px #999999 solid; }

.DashboardLayout_content__26yZy {
  padding-bottom: 32px; }

.DashboardLayout_heading__IQ5he {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px #e7e7e7 solid; }

.DashboardLayout_logoutLink__2Y4FM {
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer; }
  .DashboardLayout_logoutLink__2Y4FM:hover {
    color: #ffffff;
    text-decoration: none; }

.Node_container__313NF:hover {
  cursor: pointer; }
  .Node_container__313NF:hover .Node_rect__3zAwO {
    stroke: #999999; }

.Node_disabled__33y8z path {
  fill: #c7c7c7; }

.UserAutocompleteInput_ListDrop__3oFzP {
  position: absolute;
  z-index: 9999;
  background-color: #f7f7f7;
  margin-top: -8px;
  margin-left: 8px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  border: 1px #c7c7c7 solid;
  border-top: 0; }
  .UserAutocompleteInput_ListDrop__3oFzP .UserAutocompleteInput_Result__2xfOI {
    padding: 4px 16px;
    cursor: pointer; }
    .UserAutocompleteInput_ListDrop__3oFzP .UserAutocompleteInput_Result__2xfOI:hover {
      background-color: #ffffff; }

.ListTable_heading__2-TP0 {
  background-color: #898d94;
  color: #ffffff; }

.BoxInlineListItem_container__2Wqrv {
  padding: 8px 32px;
  border-bottom: 1px #f1f1f1 solid; }

