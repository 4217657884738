.container {
  &:hover {
    cursor: pointer;

    .rect {
      stroke: #999999;
    }
  }
}

.disabled path {
  fill: #c7c7c7;
}
