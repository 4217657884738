.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    margin: auto;

    .safeMargin {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
